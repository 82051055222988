import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import logo from '../images/pmpkin.png';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`pmpkin`, `comedy`, `entertainment`]} />
    <img
      src={logo}
      width="500"
      alt="pmpkin. coming soon."
      style={{maxWidth: '100%', boxSizing: 'border-box', margin: '10vw'}}
    />
  </Layout>
);

export default IndexPage;
